import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Facebook from '../../image/footer/facebook.png';
import TikTok from '../../image/footer/tiktok.png';
import Twitter from '../../image/footer/twitter.png';
import Instagram from '../../image/footer/instagram.png';
import FooterDonut from '../../image/footer/Group.png';
import GroupFooter from '../../image/footer/Group (1).png';
import Logo from '../../image/logo.png';


const Footer = () => {
  return (
    <footer>
      <div className='footer-container'>
      <img src={FooterDonut} className='footerDonut' alt='...' />
      <img src={GroupFooter} className='groupFooter' alt='...' />
      <div className='footer-content-left'>
        <img src={Logo} alt='...' className='footer-image' />
        <p>Leverage the power of AI to import products within a minute.</p>
        <div className='socials-footer'>
          <Link className='social' to='https://www.linkedin.com/company/shopscribe-pro/'><i className="fab fa-linkedin"></i></Link>
          <Link className='social' to='https://web.facebook.com/shopscribe.pro'><img src={Facebook} alt='...' /></Link>
          <Link className='social' to='https://x.com/Shopscribepro'><img src={Twitter} alt='...' /></Link>
          <Link className='social' to='https://www.instagram.com/shopscribe.pro/'><img src={Instagram} alt='...' /></Link>
          <Link className='social' to='https://www.tiktok.com/@shopscribe.pro'><img src={TikTok} alt='...' /></Link>
          <Link className='social' to='https://ch.pinterest.com/ShopScribePro/'><i class="fab fa-pinterest"></i></Link>
        </div>
      </div>
      <div className='footer-content-right'>
        <nav>
         INFORMATION
          <ul>
            <li><Link className='nav-item' to='/contact'>Support</Link></li>
          </ul>
        </nav>
        <nav>
          LEGAL
          <ul>
            <li><Link className='nav-item' to='/privacy-polices'>Privacy Policy</Link></li>
            <li><Link className='nav-item' to='/terms-polices'>Terms Of Use</Link></li>
            <li><Link className='nav-item' to='/refund-policy'>Refund Policy</Link></li>
          </ul>
        </nav>
      </div>
      <div className='copyright'>
        <p>Copyright © 2024 XOXO Commerce, Inc. All rights reserved.</p>
      </div>
      </div>
    </footer>
  )
};

export default Footer;
