import React from 'react';
import '../Dark/Dark.css';

const Dark = ({darkMode, dark}) => {
  return (
    <>
       <button onClick={darkMode} className='dark-bt'>
        {!dark ? (
          <i className="fas fa-moon"></i>
        ): (
          <i className="fas fa-sun"></i>
        )}
        </button>
    </>
  )
};

export default Dark;
