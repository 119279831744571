import React, {useEffect, useState } from 'react';
import Home from './components/Home/Home';
import { Routes, Route} from "react-router-dom";
import {auth, readDate} from './components/Firebase/Firebase';
import ConnectStore from './components/ConnectStore/ConnectStore';
import ImportProduct from './components/ImportProduct/ImportProduct';
import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms'; 
import Contact from './components/Contact/Contact';
import ProductHistory from './components/ProductHistory/ProductHistory';
import Profile from './components/Profile/Profile';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Refund from './components/Refund/Refund';
const stripePromise = loadStripe('pk_live_51QPQJBEjprP8xph1yH2au6laTrR4fMCeUJkwR9Afw7JsDOqCQhgsp231saZLAl3h7GQh8Mac7hWXF07vErtk5HMQ00LIbWAD4i');
function App() {
const [user, setUser] = useState(null);
const [plan, setPlan] = useState('');
useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged((user) => {
    setUser(user);
    if (user) {
      readDate(user.uid)
        .then((userData) => {
          // Fai qualcosa con i dati utente se necessario
          console.log('Dati utente:', userData.plan);
          setPlan(userData.plan)
        })
        .catch((error) => {
          console.error('Errore nella lettura dei dati utente:', error);
        });
    }
  });
  
  return unsubscribe;
}, []);

  return (
  <Elements stripe={stripePromise}>
 <Routes>
  <Route path='/' element={<Home user={user} plan={plan} setPlan={setPlan}/>} />
  <Route path='/connect' element={user ? <ConnectStore user={user} plan={plan}/> : <Home user={user} plan={plan}/>} />
  <Route path='/import-product' element={user ? <ImportProduct user={user} plan={plan} /> : <Home user={user} plan={plan}/> }/>
  <Route path='/privacy-polices' element={<Privacy user={user} />}/>
  <Route path='/terms-polices' element={<Terms user={user}/>} />
  <Route path='/contact' element={<Contact user={user}/>}/>
  <Route path='/refund-policy' element={<Refund user={user} />}/>
  <Route path='/product-history' element={user ? <ProductHistory user={user}/> : <Home user={user} plan={plan}/> }/>
  <Route path='/profile' element={user ? <Profile user={user} plan={plan} /> :  <Home user={user} plan={plan}/>}/>
 </Routes>
 </Elements>
  );
};

export default App;
