import React, {useEffect, useState} from 'react'
import DropDown from '../DropDown/DropDown'
import LogoImport from '../../image/logo-import.png';
import { ToastContainer, toast } from 'react-toastify';
import { readDate, readShop } from '../Firebase/Firebase';
import { Link } from 'react-router-dom';
import './ProductHistory.css';
import SortBy from '../SortBy/SortBy';
import Arrow from '../../image/arrow.png';
import Dark from '../Dark/Dark';

const ProductHistory = ({user}) => {
    const [shopData, setShopData] = useState([]);
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null)
    const [products, setProducts] = useState([]);
    const [data, setData] = useState(false);




    const fetchShopData = async () => {
        try {
          const response = await fetch('https://backend-8rbz.onrender.com/shop-data', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
          },
           credentials: 'include', 
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log('la sessione:', data);
            if (data.shopData && data.shopData.name) {
              setShopData(data.shopData);
              console.log('Il nome:', data.shopData.name);
              setSelectedShop(data.shopData);
            } else {
              toast.error('No store connected');
            }
          } else {
            console.error('Errore durante la richiesta:', response);
            toast.error('No store connected');
          }
        } catch (error) {
          console.error('Errore generale durante la richiesta:', error);
        };
    };
      
      
      
    const handleShopChange = (value) => {
        setSelectedShop(value);
        console.log('il valore e:', value);
      
        readDate(user.uid)
          .then((userData) => {
            const userShops = userData.shop;
      
            if (userShops && Object.keys(userShops).length > 0) {
              // Verifica che userShops esista e contenga almeno una proprietà
              Object.values(userShops).forEach(date => {
                console.log('dati:', date);
                if (date.name === value.name) {
                  console.log('result:', date.name);
                  const shopName = date.shopName;
                  const apiKey = date.apiKey;
                  const password = date.password;
                  const dates = {
                    shopName: shopName,
                    apiKey: apiKey,
                    password: password
                  };
      
                  fetch('https://backend-8rbz.onrender.com/connect-shopify', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(dates),
                  })
                    .then(response => response.json())
                    .then(data => {
                      console.log('Risposta dal server:', data);
                      setShopData(data.shopData.name);
                      toast.success(
                        <div>
                          Connect store success: {data.shopData.name}
                        </div>
                      );
                    })
                    .catch(error => {
                      console.error('Errore durante la richiesta:', error);
                      toast.error(error.message);
                    });
                }
              });
            } else {
              console.error('Nessun negozio disponibile.');
            }
          })
          .catch((error) => {
            console.error('Errore nella lettura dei dati utente:', error);
          });
    };
      
      
    useEffect(() => {
          if (user) {
            fetchShopData();
            readDate(user.uid)
              .then((userData) => {
                console.log('Dati utente:', userData);
                //setUserName(userData.username)
                console.log('user data:', userData);
                if(userData.products) {
                   setProducts(userData.products);
                };
              })
              .catch((error) => {
                console.error('Errore nella lettura dei dati utente:', error);
              });
              readShop(user.uid).then((result) => {
                setShops(result);
              });
          };
    }, [user]);      

   
    useEffect(() => {
        if (products && typeof products === 'object') {
          const productsArray = Object.values(products);
          if (productsArray.length > 0) {
            console.log('Prodotti nello stato:', productsArray);
            setData(true);
          }
        }
      }, [products]);
    
      function convertToDate(dateString) {
        const [day, month, yearAndTime] = dateString.split('/');
        const [year, time] = yearAndTime.split(' ');
        const [hour, minute, second] = time.split(':');
        return new Date(year, month - 1, day, hour, minute, second);
      };

      const [selectedValue, setSelectedValue] = useState(null);

      const handleSelect = async (value) => {
        setSelectedValue(value);
        console.log(value)
        if(value === 'Products from A to Z') {
          const productsArray = Object.values(products);
          const productsOrderedAZ = productsArray.sort((a, b) => a.title.localeCompare(b.title));
          setProducts(productsOrderedAZ);
        } else if (value === 'Products from Z to A') {
          const productsArray = Object.values(products);
          const productsOrderedZA = productsArray.sort((a, b) => b.title.localeCompare(a.title));
          setProducts(productsOrderedZA);
        } else if (value === 'Latest Products') {
          const productsArray = Object.values(products);
          const productsOrderedByDate = productsArray.sort((a, b) => convertToDate(b.date) - convertToDate(a.date));
          setProducts(productsOrderedByDate);
        } else if (value === 'Older products') {
          const productsArray = Object.values(products);
          const productsOrderedByDate = productsArray.sort((a, b) => convertToDate(a.date) - convertToDate(b.date));
          setProducts(productsOrderedByDate);
        }
      };

      const [dark, setDark] = useState(false);

      const darkMode = () => {
        setDark(!dark);
        console.log(dark);
        document.body.classList.toggle("dark");
      }; 
      
      useEffect(() => {
        if(document.body.classList.contains("dark")) {
          document.querySelector('main').classList.add("dark");
        } else {
          document.querySelector('main').classList.remove("dark");
        };
      });
      

  return (
    <div className='history'>
      <ToastContainer />
    <header className='connect-import'>
      <Link className='go-bc' to='/import-product'><img src={Arrow} /> Go Back</Link>
    <DropDown
     options={shops}
     value={selectedShop}
     onChange={handleShopChange}
    />
      <img src={LogoImport} alt='...' />
      <Dark darkMode={darkMode} dark={dark}/>
    </header>
    <main>
  <section>
    <div>
        <div className='card-container-history'>
          <h3>Imported Products History</h3>
          <div className='sort-product-history'>
          <SortBy
        options={['Products from A to Z', 'Products from Z to A', 'Latest Products', 'Older products']}
        onSelect={handleSelect}
      />
          </div>
          {Object.values(products).map((product, index) => (
            <div key={index} className='card-history'>
              <img src={product.image} alt={product.title} />
              <div className='history-content'>
              <p>{product.title}</p>
              <Link target='_blank' to={product.url}><i class="fas fa-link"></i> {product.url}</Link>
              </div>
              <span>{product.date}</span>
            </div>
          ))}
        </div>
    </div>
  </section>
</main>
    </div>
  )
}

export default ProductHistory
