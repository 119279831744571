import React, {useState} from 'react';
import './Plans.css';
import { Link } from 'react-router-dom';
import Check from '../../image/green-check.svg.png';
import isolationOne from '../../image/Vector (2).png';
import isolationTwo from '../../image/plan_2.svg.png';
import isolationThree from '../../image/plan_3.svg.png';




const Plans = ({handleClose, open}) => {
const [active, setActive] = useState(1);

const handleButtonClick = (buttonNumber) => {
    setActive(buttonNumber);
  };

  const handleClickOutside = (event) => {
    console.log(event.target.className)
    if (event.target.className !== 'card') {
      handleClose();
    };
  };

  return (
    <>
     <section className='plans-container'>
     <div id='plans' className={open === true  ? 'container page active' : 'container page'} onClick={handleClickOutside}>
     <div className='cards'>
            <div className='card'>
              <div className='card-header'>
              <span><img src={isolationOne}  alt='...' /></span>
              <p>Starter Plan</p>
            </div>
            <div className='card-content'>
            {active === 1 ? (
    <h4>$29<span>/month</span></h4>
) : (
    <h4>$182<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>50</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />1 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress Links</li>
               </ul>
               <div className='get-start'>
                <Link to='https://buy.stripe.com/cN229Z84J1JEbEk4gl' className='gt-button'>Get Started</Link>
                <p>No commitment</p>
               </div>
            </div>
            </div>
            <div className='card'>
              <div className='card-header'>
              <span><img src={isolationTwo}  alt='...' /></span>
              <p>Pro Plan</p>
              </div>
              <div className='card-content'>
              {active === 1 ? (
    <h4>$59<span>/month</span></h4>
) : (
    <h4>$470<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>150</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />3 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress or Shopify Links</li>
               </ul>
               <div className='get-start'>
                <Link to='https://buy.stripe.com/00g15V1Gl2NI4bS148' className='gt-button'>Get Started</Link>
                <p>No commitment</p>
               </div>
            </div>
              </div>
            <div className='card'>
              <div className='card-header'>
                <span><img src={isolationThree}  alt='...' /></span>
                <p>Gold Plan</p>
                </div>
                <div className='card-content'>
                {active === 1 ? (
    <h4>$89<span>/month</span></h4>
) : (
    <h4>$854<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>250</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />5 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress or Shopify Links</li>
               </ul>
               <div className='get-start'>
                <Link to='https://buy.stripe.com/fZeaGvdp3dsmcIo7sv' className='gt-button'>Get Started</Link>
                <p>No commitment</p>
               </div>
            </div>
                </div>
          </div>
          </div>
     </section>
    </>
  )
};

export default Plans;
