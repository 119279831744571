import React from 'react';
import './password.css';
import { sendPasswordResetEmail, auth } from '../Firebase/Firebase';
import { toast } from 'react-toastify';
import LoginLogo from '../../image/short-icon.png';
import 'react-toastify/dist/ReactToastify.css';


const ForGotPassword = ({handleClose,
   handleOpen, 
   open
  }) => {
    const resetPassword = (event) => {
      event.preventDefault();
      const email = event.target.emailForgot.value;
      console.log(email)
      sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        toast.success('The email to reset your password has been sent. Check your inbox.')
      })
      .catch((error) => {
        const errorCode = error.code;
        toast.error(errorCode);
        // ..
      });
    };

    const handleClickOutside = (event) => {
      console.log(event.target.className)
      if (event.target.className === 'container-forgot active') {
        handleClose();
      };
    };

  

  return (
    <>
      <div className={open === true ? 'container-forgot active' : 'container-forgot'} onClick={handleClickOutside}>
      <form className='login-form' onSubmit={resetPassword}>
      <img src={LoginLogo} alt='...'/>
       <h3>Forgot Your Password</h3>
       <p>Sure thing! Just share your email address with us, and we will send you a link to reset your password and set up a new one</p>
      <input  type="email" name="emailForgot" placeholder="email@example.com" required></input>
      <button className='submit' type='submit'>Email password reset link</button>
      <p style={{fontSize: '13px'}}>Dont want to reset your password?<button onClick={handleOpen} className='sign-up'>Log in</button></p>
      </form>
      </div>
    </>
  )
}

export default ForGotPassword;
