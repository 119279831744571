import React, {useState} from 'react';
import './SignUp.css';
import { Link } from 'react-router-dom';
import {auth, checkUsername, createUserWithEmailAndPassword, writeUserData, signInWithEmailAndPassword, sendEmailVerification,  signInWithPopup, GoogleAuthProvider, FacebookAuthProvider} from '../../components/Firebase/Firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginLogo from '../../image/short-icon.png';
import 'react-toastify/dist/ReactToastify.css';
import trustpilot from '../../image/trustpilot.png';


const SignUp = ({
  open, 
  handleClose,
  handleOpen,
  handleOpenPlans,
  }) => {
const [valid, setValid] = useState(true);
const [validTwo, setValidTwo] = useState(true);
const [passwordValue, setPasswordValue] = useState('');
const [formValid, setFormValid] = useState(false);
const [username, setUsername] = useState(true);
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();


const checkName = (event) => {
  const username = event.target.value;
  checkUsername(username).then((response) => {
    console.log(response);
    if(response !== 'Username already exists') {
        setUsername(true);
    } else {
      setUsername(false)
    };
  }).catch((error) => {
    console.error(error);
  });
};

const checkPasswordLength = (event) => {
  const password = event.target.value; 
  if(password.length >= 8 || password.length === 0) {
    setValid(true);
    if(password.length >= 8) {
       setPasswordValue(password);
    } else {
        setPasswordValue('');
    };
  }  else {
    setValid(false);
  };
};

const checkPasswordConfirmLength = (event) => {
  const password = event.target.value; 
  if(password.length >= 8 || password.length === 0) {
    setValidTwo(true);
    if(passwordValue === password) {
      setValidTwo(true);
      setFormValid(true);
    } else {
      setValidTwo(false);
      setFormValid(false);
    };
  }  else {
    setValidTwo(false);
    setFormValid(false);
  };
};



const register = (event) => {
event.preventDefault();
console.log(event.target.username.value);
const name = event.target.username.value;
const email = event.target.emailSign.value;
const password = event.target.passwordSign.value;
console.log(formValid)
console.log(passwordValue)
if(formValid === true && username === true) {
createUserWithEmailAndPassword(auth, email, password)
.then((userCredential) => {
  const user = userCredential.user;
  console.log(user.uid);
  writeUserData(user.uid,name,email);
  handleClose();
  handleOpenPlans();
  sendEmailVerification(user)
  .then((response) => {
    toast.success(
      <div>
      user created successfull Verify your email and connect you store
      <Link style={{marginLeft: '5px'}} to="/connect" target="_blank" rel="noopener noreferrer">
        connect store
      </Link>
    </div>
    );
  });
 signInWithEmailAndPassword(auth,email,password);
})
.catch((error) => {
  const errorCode = error.code;
  console.log(errorCode)
  if(errorCode === 'auth/email-already-in-use') {
    toast.error('email-already-in-use sign-in');
  } else {
     toast.error(errorCode);
  }
});
} else {
  if (formValid !== true) {
    console.log('La condizione formValid è falsa');
    toast.error("Password not correct !");
    setValidTwo(false);
  }
  if (username !== true) {
    console.log('La condizione username è falsa');
    toast.error('Username already exists');
    setUsername(false);
  };
};
event.target.reset(); 
};

const handleClickOutside = (event) => {
  console.log(event.target.className)
  if (event.target.className === 'container-form active') {
    handleClose();
  };
};


const signUpGoogle = () => {
  signInWithPopup(auth, provider)
        .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        writeUserData(user.uid, user.displayName, user.email);
        handleClose();
        handleOpenPlans();
        sendEmailVerification(user)
        .then((response) => {
          toast.success(
            <div>
            user created successfull Verify your email and connect you store
            <Link style={{marginLeft: '5px'}} to="/connect" target="_blank" rel="noopener noreferrer">
              connect store
            </Link>
          </div>
          );
        });
         }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const credential = GoogleAuthProvider.credentialFromError(error);
          toast.error(errorMessage);
    });
};


const signUpFacebook = () => {
  signInWithPopup(auth, facebookProvider)
        .then((result) => {
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log('user detail', user)
        writeUserData(user.uid, user.displayName, user.email);
        handleClose();
        handleOpenPlans();
        sendEmailVerification(user)
        .then((response) => {
          toast.success(
            <div>
            user created successfull Verify your email and connect you store
            <Link style={{marginLeft: '5px'}} to="/connect" target="_blank" rel="noopener noreferrer">
              connect store
            </Link>
          </div>
          );
        });
         }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const credential = FacebookAuthProvider.credentialFromError(error);
          toast.error(errorMessage);
    });
};

  return (
    <div className={open === true ? 'container-form active': 'container-form'} onClick={handleClickOutside}>
    <form className='signUp-form' onSubmit={register}>
    <img src={LoginLogo} alt='...'/>
      <h3>Game Changer for E-Commerce: The AI Tool Redefining Success</h3>
      <p><button type="button" onClick={handleOpen} className='sign-up p-tag'>Sign In</button>. Already have account</p>
      <input type="email" name="emailSign" placeholder='email@example.com' required></input>
      <input className={username ? '' : 'notValid'} onChange={checkName} type="text" name="username" placeholder='username' required></input>
      <div className='passsword'>
      <input  onChange={checkPasswordLength} className={valid ? '' : 'notValid'} type="password" name="passwordSign" placeholder="Enter your password" required />
      <input  onChange={checkPasswordConfirmLength} className={validTwo ? '' : 'notValid'}  type="password" name="confirmpassword" placeholder="Min 8 Characters" required />
      </div>
      <Link to='/terms-polices' className='forgot'>By registering you agree to our <b>terms and services</b></Link>
      <button  className='submit' type="submit">Sign up to My Account</button>
      <div className="line"></div>
      <button onClick={signUpGoogle}  className='google submit mt-5' type="button"><i className="fab fa-google"></i>  Continue with Google</button>
      <button onClick={signUpFacebook} className='facebook submit mt-5' type="button"><i className="fab fa-facebook-f"></i> Continue with Facebook</button>
      <img className='trust' src={trustpilot}  alt='...'/>
    </form>
    </div>
  )
};

export default SignUp;
